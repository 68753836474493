import React, { useState } from 'react';
import Select from 'react-select';
var SelectMenu = function (_a) {
    var className = _a.className, name = _a.name, id = _a.id, options = _a.options, formatOptionLabel = _a.formatOptionLabel, onChange = _a.onChange, defaultValue = _a.defaultValue, _b = _a.isMulti, isMulti = _b === void 0 ? false : _b, _c = _a.required, required = _c === void 0 ? false : _c, _d = _a.isDisabled, isDisabled = _d === void 0 ? false : _d, _e = _a.placeholder, placeholder = _e === void 0 ? 'select option' : _e, _f = _a.isSearchable, isSearchable = _f === void 0 ? false : _f, _g = _a.isClearable, isClearable = _g === void 0 ? false : _g;
    var _h = useState(defaultValue), selectedOption = _h[0], setSelectedOption = _h[1];
    return (React.createElement(Select, { name: name, id: id, className: className, onChange: function (event) {
            event ? setSelectedOption(event.value) : setSelectedOption('');
            onChange(event);
        }, defaultValue: selectedOption, options: options, formatOptionLabel: formatOptionLabel, isMulti: isMulti, required: required, isSearchable: isSearchable, isDisabled: isDisabled, placeholder: placeholder, isClearable: isClearable }));
};
export default SelectMenu;
