import { __assign } from "tslib";
import React from 'react';
var DEFAULT_ORG_LOGO = 'https://turbine-content.s3.us-east-2.amazonaws.com/default-org-logo.jpg';
var OrgLogo = function (_a) {
    var _b = _a.id, id = _b === void 0 ? '' : _b, _c = _a.height, height = _c === void 0 ? 38 : _c, _d = _a.width, width = _d === void 0 ? 100 : _d, _e = _a.className, className = _e === void 0 ? '' : _e, _f = _a.defaultSrc, defaultSrc = _f === void 0 ? DEFAULT_ORG_LOGO : _f, src = _a.src, alt = _a.alt;
    return (React.createElement("img", __assign({}, (id ? { id: id } : {}), { role: 'img', "data-testid": 'org-logo', src: src || defaultSrc, className: "".concat(className, " tw-w-auto tw-object-contain"), alt: alt || 'Organization Logo', style: {
            maxWidth: "".concat(width, "px"),
            maxHeight: "".concat(height, "px"),
            height: "".concat(height, "px")
        } })));
};
export default OrgLogo;
